import React from 'react'; // eslint-disable-line no-unused-vars
import { Box, Stack, TextField } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import { navigate } from 'gatsby';
import { Paragraph, Title } from '../components/Texts';
import Layout from '../components/Layout';

const FIELD_LIMIT = 9999;

const Contact = () => {
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    const data = new FormData(event.target);
    const formEntries = Object.fromEntries(data.entries());

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in formEntries) {
      formEntries[key] = formEntries[key].substring(0, FIELD_LIMIT);
    }

    const response = await fetch(
      '/.netlify/functions/contact',
      {
        method: 'POST',
        body: JSON.stringify(formEntries, null, 2),
      },
    );

    if (response.status === 200) {
      navigate('/merci', { replace: true });
    } else {
      // eslint-disable-next-line no-console
      console.error(`[${response.status}]]${response.statusText}`);
    }

    setLoading(false);
  };
  return (
    <Layout title="Contact">
      <Title>
        Nous contacter
      </Title>

      <Stack component="form" onSubmit={handleSubmit} spacing={2}>
        <Stack direction="row" spacing={2}>
          <TextField
            required
            name="Nom"
            label="Nom"
            sx={{ flex: 1 }}
          />
          <TextField
            required
            name="from"
            label="Votre adresse e-mail"
            sx={{ flex: 1 }}
          />
        </Stack>
        <TextField
          required
          name="Message"
          label="Message"
          multiline
          fullWidth
          rows={4}
          inputProps={{ maxLength: FIELD_LIMIT }}
          helperText="* champs obligatoires"
        />
        <Button
          type="submit"
          variant="contained"
          loading={loading}
        >
          Envoyer
        </Button>
      </Stack>

      <Box sx={{ mt: 10, textAlign: 'center' }}>
        <Paragraph>
          Éditeur : Mak Corp 52 rue Jacques Babinet 31100 Toulouse<br />
          SARL au capital de 460 000 €<br />
          SIREN 437 582 422
        </Paragraph>

        <Paragraph>
          Responsable de la publication : Catherine Tillous<br />
        </Paragraph>

        <Paragraph>
          Ce site ne fait l’objet d’aucune collecte de données personnelles.
        </Paragraph>
      </Box>
    </Layout>

  );
};

export default Contact;
